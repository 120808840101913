<template>
    <div class="pt-7 container-fluid">
        <b-card>
            <b-button variant="link" class="text-primary refresh" v-b-tooltip.hover.bottom :title="$t('general.refresh')" @click="getData">
                <i class="fas fa-sync-alt"></i>
            </b-button>
            <b-row class="filterSection">
                <b-col md="4">
                    <base-input type="text" :label="$t('general.search_area')" :placeholder="$t('doctor.search_area')" v-model="filter.text" @input="searchTimeOut">
                    </base-input>
                </b-col>
            </b-row>
        </b-card>

        <b-card no-body v-if="patients" class="p-4">
            <el-table class="table-responsive table" header-row-class-name="thead-light" :data="patients" v-loading='loading' style="width: 100%" :empty-text="$t('general.empty_table')" :row-class-name="tableRowClassName">
                <el-table-column :label="$t('general.name')" prop="name" min-width="220">
                </el-table-column>

                <el-table-column :label="$t('general.email')" prop="email" min-width="300">
                    <template v-slot="{row}">
                        <span>{{row.email}}</span>
                    </template>
                </el-table-column>

                <el-table-column :label="$t('general.phone')" prop="phone" min-width="160">
                    <template v-slot="{row}">
                        <span>{{row.phone}}</span>
                    </template>
                </el-table-column>

                <el-table-column :label="$t('profile.identity')" prop="identity" min-width="160">
                    <template v-slot="{row}">
                        <span v-if="!row.nontc">{{row.identity}}</span>
                    </template>
                </el-table-column>

                <el-table-column :label="$t('general.status')" prop="status" min-width="140">
                    <template v-slot="{row}">
                        <badge class="badge-dot mr-4" type="">
                            <i :class="`bg-${status[row.status]}`"></i>
                            <span class="status" :class="`text-${status[row.status]}`">{{row.status?$t('general.active'):$t('general.passive')}}</span>
                        </badge>
                    </template>
                </el-table-column>
                <el-table-column align="right" min-width="180">
                    <template slot-scope="scope">
                        <base-button type="primary" size="sm" @click="openProcessModal(scope.$index)">{{$t('general.process')}}</base-button>
                    </template>
                </el-table-column>
            </el-table>
            <b-card-footer class="py-4 d-flex justify-content-end" v-if="pages>1&&!loading">
                <base-pagination v-model="pagination.page" :per-page="pagination.limit" :total="total" @change="changePage"></base-pagination>
            </b-card-footer>
        </b-card>

        <b-card v-if="pages>1&&!loading" class="mt-4">
            <base-pagination v-model="pagination.page" :per-page="pagination.limit" :total="total" @change="changePage"></base-pagination>
        </b-card>

        <modal :show.sync="processModal.modal" :showClose="false" :backdropClose="false" @close="modalClose" size="lg">
            <template v-if="processModal.id=='file'">
                <h6 slot="header" class="modal-title">{{$t('general.file_list')}}</h6>
                <b-alert variant="info" :show="files.length===0">{{$t('info.no_files')}}</b-alert>
                <b-row>
                    <b-col md="3" class="mb-4" v-for="(item, index) in files" :key="index">
                        <div class="file-section">
                            <div class="file-name">
                                <a :title="item.originalName">{{item.originalName}}</a>
                            </div>
                            <div class="file-date">{{item.createdAt | getDate}}</div>
                            <div class="file-icon">
                                <i class="fas fa-file-image" v-if="item.type=='image'"></i>
                                <i class="fas fa-file-alt" v-else></i>
                            </div>
                            <div class="file-footer">
                                <base-button type="primary" size="sm" @click="openFile(item.uuid)">{{$t('general.open')}}</base-button>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </template>
            <template v-if="processModal.id=='note'" v-loading="processModal.loading">
                <h6 slot="header" class="modal-title">{{$t('general.note_list')}}</h6>
                <b-alert class="posrel" variant="secondary" show v-for="(item, index) in noteData.data" :key="index">
                    <small>
                        <strong>{{$t('general.appointment_info')}} : </strong>{{item.appointment.data.date | getIntDate}} {{item.appointment.data.time | getIntTime}} - {{$t('availability.'+item.appointment.data.type)}}</small>
                    <hr class="my-2">
                    <template v-if="userType=='admin'">
                        <strong>{{item.doctor.title}} {{item.doctor.name}}</strong><br>
                    </template>
                    <p>{{item.note}}</p>
                    <small>{{item.createdAt | getDate}}</small>
                </b-alert>
                <b-alert variant="info" :show="!noteData.data || (noteData.data && noteData.data.length==0)">{{$t('info.no_notes')}}</b-alert>
            </template>
            <template v-if="processModal.id=='recipe'" v-loading="processModal.loading">
                <h6 slot="header" class="modal-title">{{$t('general.recipe_list')}}</h6>
                <b-alert class="posrel" variant="secondary" show v-for="(item, index) in recipes" :key="index">
                    <small>
                        <strong>{{$t('general.appointment_info')}} : </strong>{{item.appointment.data.date | getIntDate}} {{item.appointment.data.time | getIntTime}} - {{$t('availability.'+item.appointment.data.type)}}</small>
                    <hr class="my-2">
                    <template v-if="userType=='admin'">
                        <strong>{{item.doctor.title}} {{item.doctor.name}}</strong><br>
                    </template>
                    <p v-html="$options.filters.getHtmlText(item.text)"></p>
                    <small>{{item.createdAt | getDate}}</small>
                </b-alert>
                <b-alert variant="info" :show="recipes.length===0">{{$t('info.no_recipe')}}</b-alert>
            </template>
            <template v-if="processModal.id=='report'" v-loading="processModal.loading">
                <h6 slot="header" class="modal-title">{{$t('general.report_list')}}</h6>
                <b-alert class="posrel" variant="secondary" show v-for="(item, index) in reports" :key="index">
                    <small>
                        <strong>{{$t('general.appointment_info')}} : </strong>{{item.appointment.data.date | getIntDate}} {{item.appointment.data.time | getIntTime}} - {{$t('availability.'+item.appointment.data.type)}}</small>
                    <hr class="my-2">
                    <template v-if="userType=='admin'">
                        <strong>{{item.doctor.title}} {{item.doctor.name}}</strong><br>
                    </template>
                    <p v-html="$options.filters.getHtmlText(item.text)"></p>
                    <small>{{item.createdAt | getDate}}</small>
                </b-alert>
                <b-alert variant="info" :show="reports.length===0">{{$t('info.no_reports')}}</b-alert>
            </template>
            <template v-if="processModal.id=='comment'" v-loading="processModal.loading">
                <h6 slot="header" class="modal-title">{{$t('general.appointment_comment')}}</h6>
                <b-alert class="posrel" variant="secondary" show v-for="(item, index) in comments" :key="index">
                    <strong>{{item.patient.name}}</strong>
                    <div class="patient-rate">
                        <b-input-group class="rateInput mb-3">
                            <b-form-rating :value="item.rate" color="#ff8800" inline readonly no-border></b-form-rating>
                        </b-input-group>
                    </div>
                    <p class="mt-2">
                        <small v-html="$options.filters.getHtmlText(item.comment)"></small>
                    </p>
                    <small>{{item.createdAt | getDate}}</small>
                    <template v-if="item.doctor_comment&&item.doctor_comment!=''">
                        <hr class="mt-2">
                        <strong>{{$t('general.your_answer')}} : </strong>
                        <small v-html="$options.filters.getHtmlText(item.doctor_comment)"></small>
                    </template>
                </b-alert>
                <b-alert variant="info" :show="comments.length===0">{{$t('info.no_comments')}}</b-alert>
            </template>
            <template slot="footer">
                <base-button type="link" class="text-danger ml-auto" @click="modalClose">{{$t('general.close')}}</base-button>
            </template>
        </modal>

        <modal :show.sync="processModal.status" modal-classes="modal-danger" modal-content-classes="bg-gradient-primary" size="sm">
            <h6 slot="header" class="modal-title">{{$t('general.process')}}</h6>

            <div class="py-3 text-center">
                <base-button type="secondary" block size="sm" @click="doProcess('file')">{{$t('general.files')}}</base-button>
                <base-button type="secondary" block size="sm" @click="doProcess('recipe')">{{$t('general.receipes')}}</base-button>
                <base-button type="secondary" block size="sm" @click="doProcess('report')">{{$t('general.reports')}}</base-button>
                <base-button type="secondary" block size="sm" @click="doProcess('note')">{{$t('general.notes')}}</base-button>
                <base-button type="secondary" block size="sm" @click="doProcess('comment')">{{$t('general.comments')}}</base-button>
            </div>

            <template slot="footer">
                <base-button type="link" class="text-white ml-auto" @click="processModal.status = false">{{$t('general.close')}}</base-button>
            </template>

        </modal>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { PATIENT_REQUEST, PATIENT_UPDATE, PATIENT_ADD, PATIENT_DELETE, PATIENT_FILES, RECIPE_REQUEST, REPORT_REQUEST, COMMENT_LIST_REQUEST } from '@/store/modules/define';
import { handleError } from '@/utils/func';
import { getCookie } from '@/utils/cookie';
let self;

const token = getCookie('user-token');
const session = getCookie('user-session');
export default {
    computed: {
        ...mapGetters({
            patients: 'getPatients',
            total: 'getPatientTotal',
            pages: 'getPatientPages',
            files: 'getFiles',
            userType: 'getUserType',
            recipes: 'getRecipes',
            reports: 'getReports',
            comments: 'getComments'
        })
    },
    data() {
        return {
            noteData: {
                text: '',
                show: false,
                data: {}
            },
            processModal: {
                appointment: {},
                loading: false,
                status: false,
                index: false,
                modal: false,
                data: {},
                id: '',
                type: '',
                payment: ''
            },
            loading: true,
            status: {
                true: 'success',
                false: 'danger'
            },
            pagination: {
                limit: 10,
                page: 1
            },
            filter: {},
        };
    },
    methods: {
        openProcessModal(index) {
            self.processModal.index = index;
            self.processModal.status = true;
        },
        tableRowClassName({ row, rowIndex }) {
            if (!row.status) {
                return 'table-danger';
            }
            return '';
        },
        showNote() {
            self.noteData.show = true;
        },
        showRecipe() {
            self.recipeData.show = true;
        },
        showReport() {
            self.reportData.show = true;
        },
        searchTimeOut() {
            if (self.timer) {
                clearTimeout(self.timer);
                self.timer = null;
            }
            self.timer = setTimeout(() => {
                self.getData(true);
            }, 800);
        },
        doProcess(process) {
            self.processModal.status = false;
            let row = self.patients[self.processModal.index];
            switch (process) {
                case 'file':
                    self.getPatientFile(row.uuid);
                    break;
                case 'note':
                    self.handleNote(row.uuid, self.processModal.index);
                    break;
                case 'recipe':
                    self.getPatientRecipe(row.uuid);
                    break;
                case 'report':
                    self.getPatientReport(row.uuid);
                    break;
                case 'comment':
                    self.getComment(row.uuid);
                    break;
                default:
                    break;
            }
        },
        changePage(val) {
            self.loading = true;
            self.pagination.page = val;
            self.getData();
        },
        handleNote(uuid, index) {
            self.processModal.id = 'note';
            self.processModal.modal = true;
            self.processModal.loading = true;
            self.$store
                .dispatch('PATIENT_NOTE_REQUEST', { uuid, index })
                .then(resp => {
                    self.noteData.data = self.patients[index].notes !== undefined ? self.patients[index].notes : [];
                    self.processModal.loading = false;
                })
                .catch(err => {
                    self.processModal.loading = false;
                    self.processModal.modal = false;
                    self.noteData.data = null;
                    handleError(self, err);
                });
        },
        modalClose() {
            self.noteData = {
                text: '',
                show: false,
                data: {}
            };
            self.processModal = {
                appointment: {},
                loading: false,
                status: false,
                index: false,
                modal: false,
                data: {},
                id: '',
                type: '',
                payment: ''
            };
        },
        getData(pageReset=false) {
            if(pageReset){
                self.pagination.page = 1;
            }
            self.$store
                .dispatch(PATIENT_REQUEST, { pagination: self.pagination, filter: self.filter })
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
        },
        getPatientFile(uuid) {
            self.processModal.id = 'file';
            self.processModal.modal = true;
            self.processModal.loading = true;
            self.$store
                .dispatch(PATIENT_FILES, { patient: uuid })
                .then(resp => {
                    self.processModal.loading = false;
                })
                .catch(err => {
                    self.processModal.loading = false;
                    handleError(self, err);
                });
        },
        openFile(uuid) {
            var xhr = new XMLHttpRequest();

            xhr.open('GET', process.env.VUE_APP_CDNURL + '/' + uuid);
            xhr.onreadystatechange = () => {
                if (xhr.status == 200 && xhr.response) {
                    var data_url = URL.createObjectURL(xhr.response);
                    window.open(data_url);
                }
            };
            xhr.responseType = 'blob';
            xhr.setRequestHeader('Authorization', token);
            xhr.setRequestHeader('Session', session);
            xhr.send();
        },
        getPatientRecipe(uuid) {
            return new Promise((resolve, reject) => {
                self.processModal.id = 'recipe';
                self.processModal.modal = true;
                self.processModal.loading = true;
                self.$store
                    .dispatch(RECIPE_REQUEST, {
                        filter: { patient: uuid },
                        pagination: { limit: 10000 }
                    })
                    .then(resp => {
                        self.processModal.loading = false;
                        resolve(true);
                    })
                    .catch(err => {
                        self.processModal.loading = false;
                        handleError(self, err);
                        reject(true);
                    });
            });
        },
        getPatientReport(uuid) {
            return new Promise((resolve, reject) => {
                self.processModal.id = 'report';
                self.processModal.modal = true;
                self.processModal.loading = true;
                self.$store
                    .dispatch(REPORT_REQUEST, {
                        filter: { patient: uuid },
                        pagination: { limit: 10000 }
                    })
                    .then(resp => {
                        self.processModal.loading = false;
                        resolve(true);
                    })
                    .catch(err => {
                        self.processModal.loading = false;
                        handleError(self, err);
                        reject(true);
                    });
            });
        },
        getComment(uuid) {
            return new Promise((resolve, reject) => {
                self.processModal.id = 'comment';
                self.processModal.modal = true;
                self.processModal.loading = true;
                self.$store
                    .dispatch(COMMENT_LIST_REQUEST, { filter: { patient: uuid } })
                    .then(resp => {
                        self.processModal.loading = false;
                        resolve(true);
                    })
                    .catch(err => {
                        self.processModal.loading = false;
                        handleError(self, err);
                        reject(true);
                    });
            });
        }
    },
    created() {
        self = this;
        self.getData();
    }
};
</script>
